<template>
  <div class="page">
    <div class="main">
      <img
        src="https://directimg.miaocang.cc/mpImg/attraction/driver/attraction@2x.png"
        draggable="false"
        oncontextmenu="return false;"
        class="unselectable"
        style="min-height: 100vh"
      />
      <div class="text">
        <div @click="toDriverCooperationPage">附件：《苗仓地被直采中心承运商合作协议》</div>
        <div>咨询热线：400-838-6166</div>
      </div>
    </div>
    <div v-if="isNeedLogin === '1'" class="footer">
      <van-button class="half-button" color="#2387fe" @click="toApplyPage">申请入驻</van-button>
      <van-button class="half-button" color="#999" plain @click="toWxLoginPage">登录</van-button>
    </div>
    <div v-else class="footer">
      <van-button class="large-button" size="large" color="#2387fe" @click="toApplyPage">立即报名</van-button>
    </div>
  </div>
</template>

<script>
import wx from 'weixin-js-sdk'

export default {
  name: 'DriverAttraction',

  data() {
    return {
      // 底部是否需要显示登录按钮
      isNeedLogin: 0
    }
  },

  methods: {
    /* 跳转到货物委托运输服务合作协议页 */
    toDriverCooperationPage() {
      this.$router.push({
        name: 'DriverCooperation'
      })
    },

    /* 申请入驻的点击事件处理(跳转到华南项目的申请入驻H5页) */
    toApplyPage() {
      window.open('https://sc.miaocang.cc/#/mBusiness')
      // wx.miniProgram.navigateTo({
      //   url: '/pages/webView/webView?src=https://sc.miaocang.cc/#/mBusiness'
      // })
    },
    
    /* 登录按钮的点击事件处理(跳转小程序内的登录页) */
    toWxLoginPage() {
      wx.miniProgram.navigateTo({
        url: '/pages/auth/login/login'
      })
    }
  }
}
</script>

<style lang="less" scoped>
.page {
  height: 100%;
  background-color: #2387fe;
  .main {
    padding: 28px 0 112px;
    img {
      width: 100vw;
      object-fit: cover;
    }
    .text {
      margin-top: 28px;
      padding: 0 14px;
      text-align: left;
      font-size: 14px;
      color: #fff;
    }
  }
  .footer {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 70px;
    padding-top: 14px;
    text-align: center;
    background-color: #fff;
    z-index: 1000;
    .half-button {
      width: 43.6vw;
      &:first-child {
        margin-right: 14px;
      }
    }
    .large-button {
      width: 92vw;
    }
  }
}
</style>